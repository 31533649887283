.title {
  font-size: 1.2em;
  font-family: $main-font;
}
.cards, .cards__title, .cards__text{
  margin: 0;
}
.op{
  margin-top: -1em;
  // background: #008;
}
.cards{
  $bg: rgba(color(yellow),.15);
  margin-top: $gutter * 2;
  display: flex;
  flex-direction: column;
  border: 2px solid $bg;
  border-radius: $gutter;
  padding: $gutter;
  background: rgba(color(yellow),.05);
  box-shadow: box3d(2,$bg);

  //height: 21em;
  @include from(tablet){
    margin: 0 $gutter;
    margin-bottom: 2em !important;
    width: calc(50% - 2em);
  }
  @include from(web){
    width: calc(25% - 2em);
  }
  &__icon{
    $width: 4.5rem;
    display: table;
    margin: 0 auto;
    font-size: 2.5em;
    width: $width;
    line-height: $width;
    color: $menu-bg-hover;
    text-align: center;
    box-shadow: 0 0 0 2px $menu-bg-hover;
    border-radius: 50%;
    background: $bg-color;
    transform: scale(.7);
    //color: $bg-menu-hover;
    @include desde(web){
      transform: scale(.9);
      //margin-top: 5em;
    }
  }
  &__title{
    font-size: 1.1em;
    text-align: center;
    margin-top: $gutter;
    margin-bottom: $gutter;
    text-transform: uppercase;
  }
  &__text{
    font-size: .9em;
    text-align: center;

    //margin-bottom: $gutter;
  }
  &__link{
    display: block;
    width: 100%;
    text-align: center;
    font-size: .9em;
    & > img{
      display: block;
      margin: 0 auto;
      margin-top: rem(5px);
      width: 100%;
      max-width: 300px;
    }
  }
  .btn-small{
    @include from(tablet) {
      margin-top: auto;
    }
  }
  a{
    //margin-top: $gutter;
    border-radius: em(15px);
  }
  .card-txt{
    margin-bottom: $gutter;
  }
}
.news{
  &__title{
    margin: 0;
    margin-top: $gutter;
    @extend .title
  }
  &__date{
    margin-left: $gutter/3;
    font-size: .6em;
  }

}
.pagina{
  p{
    span{
      font-weight: 700;
    }
  }
}
.registro{
  margin-top: $gutter;
  margin-bottom: $gutter;
}
.abautus{
  justify-content: center;
}
.galeria{
  img, a{
    display: block;
  }
  img{
    transition: $transition;
    &:hover{

    }
  }
  a{
    width: 90%;
    margin: $gutter auto 0;
    border: border($menu-bg, 2px);
    position: relative;
    overflow: hidden;
    &::after{
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 100%;
      left: 0;
      background: rgba(#000,.75) url(../img/gal.png) center center no-repeat;
      transition: $transition;
    }
    &:hover{
      &::after{
        top:0;
      }
      img{
        transform: scale(1.9) rotate(-10deg);
        filter: grayscale(100%);
      }
    }
  }
}

input[type=number] {
   appearance: textfield;
 }
.contacto{
  h3{
    text-align: center;
    font-size: em(16px);
    margin-bottom: 0;
    @include from(web){
      -ms-text-align-last: right;
      text-align: left;
    }
  }
}
.vermapa{
  font-size: 65%;
  text-transform: uppercase;
  margin-left: em(5px);
  &::before{
    content: '(';
  }
  &::after{
    content: ')';
  }
  &:hover{
    text-decoration: underline;
  }
}
.domicilio{
  h3{
    margin-bottom: 0;
  }
}
.lista{
  list-style: none;
  margin: 0 auto;
  padding: 0;

  @include desde(web){
    //max-width: 30%;
  }
  //background: #000;
  li{
    text-align: center;
    &:before{
      content: '\4a';
      font-family: "iconos-web";
      position: relative;
      top: em(2px);
      left: em(-5px);
      font-size: 80%;
    }
  }
}
.lbl-select{
  display: block;
  font-size: 1.2em;
  margin-top: 1em;
  margin-bottom: .5em;
}
select{
  appearance: none;
  &:focus{
    outline: none;
  }
}
.select{
  display: block;
  background: url(../img/arrow-down.jpg) no-repeat right center;
  height: rem(34px);
  width: 100%;
  // max-width: rem(400px);
  
  border: border($menu-bg, 1px);
  padding-left: em(7px);
  margin-bottom: $gutter*2;
  
}
.input-group{
  margin-bottom: $gutter;
  label, input, textarea {
    display: block;
  }
  input, textarea {
    width: 100%;
    border: 1px solid rgba($menu-bg,.3);
    padding: rem(3px) rem(5px);
  }
  label{
    display: block;
    margin-bottom: .2em;
  }
  textarea{
    height: 5em;
    resize: none;
  }
}
#button{
  color: $body--background;
  font-weight: bold;
  text-transform: uppercase;
}
.form-recruitment, .form-quotation{
  display: none;
}
.mensaje{
  height: 6em;
  margin-top: 3em;
  margin-bottom: 3em;
  background: rgba(#ccc,.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 120%;
  @include to(398px){
    font-size:105%;
  }
  p{
    margin: 0;
    text-align: center;
    font-weight: 700;
  }
  .success{
    color: color(green);
  }
  .error{
    color: color(red);
  }
}
.aside-inline{
  display: none;
}
.aviso{
  display: block;
  width: 80%;
  max-width: 300px;
  line-height: 2em;
  text-align: center;
  margin: 1em auto 0;
  color: color(white);
  background: $main-color;
  transition: all .3s;
  &:hover{
    background: lighten($main-color,10);
  }
}
.list-aviso{
  list-style: upper-roman;
}
.page-aviso{
  a{
    &:hover{
      text-decoration: underline;
    }
  }
}