// Videos responsive
.ed-video {
  height         : 0;
  overflow       : hidden;
  padding-bottom : 56.25%;
  position: relative;
    
  > iframe,
  > object,
  > video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}