/*
todo: las clases de alineacion (como justify, top, bottom etc) deben poder
aplicarse a un item unico o a todo_ un container para que se herede en
todos sus items.
*/

// Orden de los items
// usar clases del tipo .size-order-n
// donde size es un tamaño de ed-grid y n es cualquier entero del 1 al 10.
@each $size, $screen-size in $breakpoints{
  @media screen and (min-width: $screen-size){
    @for $i from 1 through 10 {
      .#{$size}-order-#{$i} {
        order: $i;
      }
    }
  }
}

// Alineacion en el main axis
.justify {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-justify {
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.distribute,
.distributed {
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-distribute,
    .#{$size}-distributed {
      justify-content: space-around;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.main-center {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-main-center {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.main-start {
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-main-start {
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.main-end {
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-main-end {
      justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// Alineación en el cross axis
.cross-start {
  align-items: flex-start;
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-cross-start {
      align-items: flex-start;
      align-content: flex-start;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.cross-center {
  align-items: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-cross-center {
      align-items: center;
      align-content: center;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.cross-end {
  align-items: flex-end;
  align-content: flex-end;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-cross-end {
      align-items: flex-end;
      align-content: flex-end;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// Orientación del container
.reverse {
  flex-direction: row-reverse;
  display: flex;
  flex-wrap: wrap;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-reverse {
      flex-direction: row-reverse;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.column {
  flex-direction: column;
  display: flex;
  flex-wrap: wrap;
}

@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-column {
      flex-direction: column;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.column-reverse {
  flex-direction: column-reverse;
}
@each $size, $screen-size in $breakpoints {
  @media all and (min-width: $screen-size){
    .#{$size}-column {
      flex-direction: column-reverse;
      display: flex;
      flex-wrap: wrap;
    }
  }
}

// Centrado absoluto (vertical y horizontal)
.abcenter {
  display : flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
