// todo: revisar que mixins ya no son útiles en la nueva versión con flexbox

// Mixins para media queries
// El parámetro es el nombre del breakpoint

@mixin hasta($size){
  @if map-has-key($breakpoints,$size){
  // Si el parámetro es el nombre de un tamaño, se extrae desde el map
    @media screen and (max-width: map-get($breakpoints,$size) - 1){
      @content;
    }
  } @else {
  // Si el parámetro no existe en el map, se toma su valor para construir la media query
    @media screen and (max-width: $size){
      @content;
    }
  }
}

@mixin to($size){
  @if map-has-key($breakpoints,$size){
    // Si el parámetro es el nombre de un tamaño, se extrae desde el map
    @media screen and (max-width: map-get($breakpoints,$size) - 1){
      @content;
    }
  } @else {
    // Si el parámetro no existe en el map, se toma su valor para construir la media query
    @media screen and (max-width: $size){
      @content;
    }
  }
}

@mixin desde($size){
  @if map-has-key($breakpoints,$size){
  // Si el parámetro es el nombre de un tamaño, se extrae desde el map
    @media screen and (min-width: map-get($breakpoints,$size)){
      @content;
    }
  } @else {
  // Si el parámetro no existe en el map, se toma su valor para construir la media query
    @media screen and (min-width: $size){
      @content;
    }
  }
}

@mixin from($size){
  @if map-has-key($breakpoints,$size){
    // Si el parámetro es el nombre de un tamaño, se extrae desde el map
    @media screen and (min-width: map-get($breakpoints,$size)){
      @content;
    }
  } @else {
    // Si el parámetro no existe en el map, se toma su valor para construir la media query
    @media screen and (min-width: $size){
      @content;
    }
  }
}

@mixin desde-hasta($desde,$hasta){
  @if map-has-key($breakpoints,$desde) and map-has-key($breakpoints,$hasta){
    @media screen and (min-width: map-get($breakpoints,$desde)) and (max-width: map-get($breakpoints,$hasta) - 1){
      @content;
    }
  } @else if map-has-key($breakpoints,$desde) and not map-has-key($breakpoints,$hasta){
    @media screen and (min-width: map-get($breakpoints,$desde)) and (max-width: $hasta) {
      @content;
    }
  } @else if not map-has-key($breakpoints,$desde) and map-has-key($breakpoints,$hasta) {
    @media screen and (min-width: $desde) and (max-width: map-get($breakpoints,$hasta) - 1){
      @content;
    }
  } @else {
    @media screen and (min-width: $desde) and (max-width: $hasta){
      @content;
    }
  }
}

@mixin from-to($desde,$hasta){
  @if map-has-key($breakpoints,$desde) and map-has-key($breakpoints,$hasta){
    @media screen and (min-width: map-get($breakpoints,$desde)) and (max-width: map-get($breakpoints,$hasta) - 1){
      @content;
    }
  } @else if map-has-key($breakpoints,$desde) and not map-has-key($breakpoints,$hasta){
    @media screen and (min-width: map-get($breakpoints,$desde)) and (max-width: $hasta) {
      @content;
    }
  } @else if not map-has-key($breakpoints,$desde) and map-has-key($breakpoints,$hasta) {
    @media screen and (min-width: $desde) and (max-width: map-get($breakpoints,$hasta) - 1){
      @content;
    }
  } @else {
    @media screen and (min-width: $desde) and (max-width: $hasta){
      @content;
    }
  }
}

// Mixins para cuadriculas
// Se aplica al contendor de la cuadricula. 
// Los parámetros son el selector de cada item y el número de columnas
// El tercer parámetro (opcional) es la separacion entre columnas (no son validos los porcentajes)
// todo: clases del tipo size-grid-n para poder crear grids por clases (no solo por sass) de preferencia en un archivo aparte para que lo desactiven quienes van a usar solo sass

@function item-width($width,$separacion){
  @return calc(#{$width} - #{$separacion});
}

@mixin cuadricula($items, $columnas, $separacion:1em){
  $width: 100% / $columnas;
  display: flex;
  flex-wrap: wrap;
  margin-left: -($separacion / 2);
  margin-right: -($separacion / 2);

  // por si el contenedor es un ul
  @if $items == li {
    margin     : 0;
    padding    : 0;
    list-style : none;

    li {
      margin-left: 0;
    }
  }
  & > #{$items}, & > .#{$items}{
    width  : item-width($width,$separacion);
    margin : $separacion / 2;
  }
}

@mixin grid($items, $columnas, $separacion:1em){
  $width: 100% / $columnas;
  display: flex;
  flex-wrap: wrap;
  margin-left: -($separacion / 2);
  margin-right: -($separacion / 2);

  // por si el contenedor es un ul
  @if $items == li {
    margin     : 0;
    padding    : 0;
    list-style : none;

    li {
      margin-left: 0;
    }
  }

  & > #{$items}, & > .#{$items}{
    width  : item-width($width,$separacion);
    margin : $separacion / 2;
  }

}

// Mixin para controlar el padding
// El parámetro es por cuanto se va a multiplicar el padding base.
@mixin padding($numero){
  padding-left: $padding * $numero;
  padding-right: $padding * $numero;
}

// Mixin para proporcion de aspecto, se le pasan tres parámetros
// Horizontal, Vertical y Ancho. Este ultimo es opcional, si no 
// se pasa, se tomará el 100% de ancho por defecto.
@mixin proporcion($h,$v,$width:100%){
  width          : $width;
  height         : 0;
  overflow       : hidden;
  padding-bottom : $width * ($v/$h);
}

@mixin limpiar {
  &:before,&:after{
    content: "";
    width: 100%;
    display: table;
    clear: both;
  }
}

@mixin derecha($width:auto,$margin-left:1em){
  float: right;
  width: $width;
  margin-left: $margin-left;
}

@mixin izquierda($width:auto,$margin-right:1em){
  float: left;
  width: $width;
  margin-right: $margin-right;
}

@mixin centro($width:auto){
  display: table;
  width: $width;
  margin-left: auto;
  margin-right: auto;
}