// todo: estrategia para subniveles en moviles

// Por defecto el menú es vertical. Se debe añadir la clase
// .tablet-horizontal o .web-horizontal para definir a partir
// de qué tamaño será horizontal
.icon-menu{
  background: darken($menu-bg,5);
  text-align: center;
  line-height: 3rem;
  font-size: 1.5em;
  cursor: pointer;
  color: $menu-color-a;
  transition: $transition;
  &:hover{
    background: $menu-bg-hover;
  }
}
.menu {
  // resetear los estilos de menu principal y submenus
  position: relative;
  z-index: 20000;
  &,ul{
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
  }

  // preparar contexto para posicionar los submenus
  li {
    position: relative;
  }

  ul {
    // Submenus ocultos y posicionados por defecto
    position: relative;
    display: block;
    width: 100%;
    @include from(web){
      position: absolute;
      display: none;
    }
  }

  // Igualar tamaño de los <a> a su contenedor <li>
  a {
    display: block;
    width: 100%;
    line-height: 3em;
    padding: 0 1em;
    @include from(web){
      line-height: 3.5em;
    }
  }

  // Estilo predeterminado (añadiendo la clase .default)
  &.default {
    background: $menu-bg;
    li {
      // Permitir a los <li> crecer hasta llenar todo_ el espacio
      flex: 1;
      // El hover en el <li> (y no en <a>) se mantiene aunque se descienda a sus hijos
      transition: $transition;
      &:hover {
        background: $menu-bg-hover;
      }
    }
    // submenús
    ul {
      //background: darken(#eee,5);
      padding-left: 1.5em;
      @include from(web){
        background: $menu-bg-hover;
        padding-left: 0;
      }

      & a{
        font-size: .8em;
      }
    }
    a {
      color: $menu-color-a;
      text-transform: $menu-text-transform;
      transition: $transition;
      font-size: .9em;
      @include from-to(1024px,1063px){
        font-size: .7em;
      }
      @include  from-to(1064px,1194px){
        font-size: .8em;
      }
      &:hover{
        color: $menu-color-a-hover;
      }
    }
  }
  // Estilo barra de navegación (con la clase .nav-bar)
  &.nav-bar {
    // hereda todas las propiedades de .default
    @extend .default;
    $border: $menu-border;
    border: $border;
    // Bordes inferiores a los <li> menos el último
    // porque el menú es vertical
    li {
      border-bottom: 1px solid rgba(darken($menu-bg,10),.75);
      &:last-child {
        border-right: none;
      }
    }

    // Submenús
    ul {
      border: $border;
      li {
        border-bottom: $border;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  // Estilo barra de botones (añadiendo la clase .button-bar)
  &.button-bar {
    li {
      margin-bottom: .5em;
      margin-right: .5em;
    }
    a {
      border: 1px solid rgba(#000, .1);
      display: block;
      background: #eee;
      line-height: 2.5em;
      margin-right: .5em;
      padding: 0 1.5em;
      border-radius: 4px;
      color: #666;
      &:hover {
        background: rgba(#000,.1);
      }
    }
  }
}

// Mixin para crear menús horizontales según tamaños
@mixin menu-horizontal($size) {
  @include from($size){
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &.nav-bar {
      > li {
        border-right: $menu-border;
        border-bottom: none;
      }
    }

    a {
      text-align: center;
    }
    // Submenús
    ul {
      position: absolute;
      top: 100%;
      min-width: 10em;
      ul {
        // Posicion de los sub-sub menús
        top: 0;
        left: 100%;
      }
    }
    // Mostrar los submenus
    li:hover > ul{
      display: none;
    }
  }
}

// Menú horizontal desde movil
.movil-horizontal {
  @include menu-horizontal(movil)
}

// Menú horizontal desde tablet
.tablet-horizontal {
  @include menu-horizontal(tablet)
}

// Menu horizontal desde web
.web-horizontal {
  @include menu-horizontal(web)
}