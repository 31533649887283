//configuraciones Generales
@import "config/colors";
@import "config/fonts";
@import "config/variables";

// Layuot
@import "layout/master";

// Base Theme
@import "theme/base";

// UI
@import "ui/master";

//theme
@import "theme/menus";
@import "theme/header";
@import "theme/footer";
@import "theme/main";
@import "theme/aside";

// modules
@import "modules/modules";
.ba{
  overflow: hidden;
  height: auto;
}