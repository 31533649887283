.top-bar{
  background: lighten(color(grey-light),12);
  min-height: 1em;
  border-bottom: border(2px,rgba(color(grey),.3));
  color: rgba(color(grey),.95);
  font-size: .9em;

  p, a{
    color: inherit;
    line-height: 1.8em;
    @include to(tablet){
      text-align: center;
    }
  }
}
$persp: 100px;
.logo-container{
  width: 100%;
  margin-top: 1em;
}
.logo-img{
  width: $persp;
  margin: 1.5em auto 0;
  perspective: $persp;
  img{
    transition: all 2.5s linear;
  }
}
#persp{
  animation: girar 10s linear infinite;
  @keyframes girar{
    20%{
      transform: rotateY(180deg);
    }
    100%{
      transform: rotateY(180deg);
    }
  }
}
.logo-text{
  position: relative;
  width: 90%;
  max-width: 300px;
  margin: -1em auto 0;
  h1{
    text-transform: uppercase;
    font-family: $logo-font;
    font-size: 3em;
    color: color(red);
    font-weight: bold;
    text-shadow: texto3d(5,darken(color(red),27));
    text-align: center;
  }
  span{
    display: block;
    text-transform: capitalize;
    font-family: $main-font;
    font-size: .4em;
    color: color(grey-dark);
    text-shadow: none;
  }
  &:after, &:before{
    content: "";
    position: absolute;
    width: 80%;
    height: 3px;
    background: color(red);
    top: 60%;
    left: -90%;
    @include hasta(web){
      display: none;
    }
  }
  &:after{
    left: 110%;
  }
}
.no-padding-rigth{
  @include from(web) {
    padding-right: 0 !important;
  }
}
.no-padding-left{
  @include from(web) {
    padding-left: 0 !important;
  }
}
.menu{
  @include from(web) {
    border-bottom: 5px solid $menu-bg-hover !important;
  }
}
.selected{
  background: $menu-bg-hover !important;
}
.network{
  background: darken($menu-bg,10) !important;
  .icon-facebook:hover{
    background: color(facebook);
  }
  .icon-youtube:hover{
    background: color(youtube);
  }
}