// todo: v2. mixins ed-container() y ed-item() para aplicarlos donde se requiera
// todo: los selectores ed-container y ed-item en variables para poderse modificar
/*
  GRUPOS Y CAJAS
  ==============
  Los grupos son contenedores para las cajas.
  Las cajas crean las divisiones en el grupo (columnas).
  En ED GRID 1.2. los grupos son containers y las
  cajas son items. Para crear un namespace se usan las
  clases ed-container y ed-item. Se pueden seguir usando
  las clases .grupo y .caja pero se desaconseja (en la v2 desaparecerán)
*/

.#{$container} {
  display      : flex;
  flex-wrap    : wrap;
  max-width    : $max-width;
  margin-left  : auto;
  margin-right : auto;
  width        : 100%;

  &.full {
    max-width : 100%;
  }
}

.#{$item} {
  margin        : 0;
  padding-left  : $padding;
  padding-right : $padding;
  width         : 100%;
}


// Base sizes in percentages
@for $i from 1 through 20 {
  .base-#{$i*5},
  .#{$item}.base-#{$i*5}{
    width: percentage($i * 0.05);
  }
}

// Tamaños base en fracciones
@each $fraction in $fractions{
  @for $i from 1 through $fraction{
    .base-#{$i}-#{$fraction},
    .#{$item}.base-#{$i}-#{$fraction}{
      width: (100% / $fraction) * $i;
    }
  }
}


// Breakpoints
@each $size, $screen-size in $breakpoints{
  // Loops para tamaños en porcentajes y fracciones
  @media all and (min-width: $screen-size){
    // Tamaños en porcentajes
    @for $i from 1 through 20 {
      .#{$size}-#{$i*5},
      .#{$item}.#{$size}-#{$i*5}{
        width: unquote($i*5 + "%");
      }
    }
    //Tamaño en fracciones
    @each $fraction in $fractions{
      @for $i from 1 through $fraction{
        .#{$size}-#{$i}-#{$fraction},
        .#{$item}.#{$size}-#{$i}-#{$fraction}{
          width: (100% / $fraction) * $i;
        }
      }
    }
  }
}
