//Tipo de fuentes generales ** desactive las que no  necesite para mayor dendimiento
//@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,700';
//$open-sans:'Open Sans', sans-serif;
//
//@import 'https://fonts.googleapis.com/css?family=Oswald:300,400,700';
//$oswald : 'Oswald', sans-serif;

@import 'https://fonts.googleapis.com/css?family=Crimson+Text:400,700';
$crimson : 'Crimson Text', serif;
//
//@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700';
//$montserrat : 'Montserrat', sans-serif;
//
@import 'https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700';
$roboto-condensed : 'Roboto Condensed', sans-serif;
//
//@import 'https://fonts.googleapis.com/css?family=Ubuntu:300,400,700';
//$ubuntu : 'Ubuntu', sans-serif;
//
//@import 'https://fonts.googleapis.com/css?family=Noto+Sans:400,700';
//$noto : 'Noto Sans', sans-serif;

@font-face {
  font-family: 'ethnocentricregular';
  src: url('ethnocentric/ethnocentric.regular-webfont.woff2') format('woff2'),
  url('ethnocentric/ethnocentric.regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

$main-font : $roboto-condensed;
$logo-font: $crimson;
$header-font: 'ethnocentricregular';

$light      : 300;
$normal     : 400;
$bold       : 700;