.network{
   .pagina{
     display: flex;
     height: 3em;
     justify-content: center;
     align-items: center;
     @include from(web){
       height: 3.5em;
     }
     img{
       width: 22px;
     }
   }
 }