// Botones, el mixin button recibe tres parametros (todos opcionales)
// color de fondo, color de texto, estilo (round o radius)
@mixin button($bg-color: #ddd,$front-color: #333,$style: default){
  display: inline-block;
  border: none;
  line-height: 2.5em;
  background: $main-color;
  color: $front-color;
  padding: 0 1.5em;
  cursor: pointer;
  //margin-bottom: 1em;
  transition: all .3s;
  &:hover {
    background: darken($main-color, 5);
  }
  &:active {
    transform: scale(.97);
  }
  // estilos del boton (radius o round)
  @if($style == radius){
    border-radius: em(10px);
  } @else if($style == round) {
    border-radius: em(10px);
  }
}

// Aplicar el mixin button()
// Puede aplicarlo a otras clases o crear variaciones con parametros
.boton, .button, button {
  @include button();
  &.round {
    @include button($bg-color: #ddd,$front-color: #333,$style: round);
  }
  &.radius {
    @include button($bg-color: #ddd,$front-color: #333,$style: round);
  }
}

