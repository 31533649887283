@each $name, $color in $colors{
  .bg-#{$name}{
    background-color: #{$color};
  }
  .txt-#{$name}{
    color: #{$color};
  }
  button.#{$name},a.#{$name}{
    background-color: #{$color};
    &:hover{
      background: darken($color,10);
    }
  }
}
$btn-sices:(
  small: rem(15px),
  medium: rem(15px),
  large: rem(25px),
  xlarge: rem(35px)
);
@function btn-sice($name){
  $sice: map-get($btn-sices,$name);
@return $sice;
}

@each $name,$sice in $btn-sices{
  .btn-#{$name}{
    padding-left: $sice;
    padding-right: $sice;
    line-height: $sice*1.5;
    font-size: $sice*.9;
  }
}
.thumbnail{
  $width: rem(120px);
  display: inline-block;
  padding: 5px;
  width: $width;
  height: $width;
  margin: .5em;
  border: 1px solid $main-color;
}
.roud{
  border-radius: em(10px);
}
