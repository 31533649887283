.footer{
  background: $main-color;
  margin-top: $gutter * 2;
  color: color(white);
  padding: 1em 0;
  p{
    line-height: 1.5;
  }
  a{
    margin-left: em(5px);
    color: lighten(color(blue-light),30);
    transition: $transition;
    display: inline-block;
    &:hover{
      //transform: scale(1.03);
      text-decoration: underline;
      //margin-left: em(10px);
    }
  }
}