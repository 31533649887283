.slogan{
  text-align: center;
  font-family: 'ethnocentricregular';
  font-size: 1.5em;
  margin: 2rem 0  2rem;
  color: $menu-bg-hover !important;
  text-shadow: texto3d(3,rgba($menu-bg,.5));
}
.section-title{
  $bg-color: $menu-bg;
  position: relative;
  width: 100%;
  margin-bottom: $gutter;
  h2{
    background: $bg-color;
    $sice: 2.5em;
    @extend .title;
    display: table;
    margin: 0;
    line-height: $sice;
    height: $sice;
    padding: 0 1.5em;
    color: color(white);
    font-family: 'ethnocentricregular';
  }
  &::before{
    $sice: 4px;
    content: "";
    display: block;
    height: $sice;
    width: 100%;
    position: absolute;
    top: calc(100% - #{$sice});
    background: $bg-color;
  }
}
.line{
  height: 3px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.section{
  margin-top: $gutter ;
}
.banner-img{
  display: block;
  width: 100%;
}
.inicio{
  .not{
    position: relative;
    top: -1em;
  }
  .section-title{
    max-width: 320px;
    //margin-bottom: $gutter * .4;
  }
}
.pagina{
  p{
    display: block;
    width: 100%;
    span{
      margin-right: em(5px);
    }
    &.strong{
      font-weight: 700;
    }
  }
  ul{
    //@extend %list-style;
    margin-top: 0;
  }
}
#inline_content{
  background: $body--background !important;
  padding: 1em 2em;
  .paginasamigas{
    @extend %list-style;
    li{
      margin-bottom: $gutter * 2;
      &:nth-child(2){
        margin-bottom: 0;
      }
    }
    a{
      padding-left: 2em;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        $sice: rem(32px);
        width: $sice;
        height: $sice;

        left: rem(-5px);
        top: rem(-6px);
      }
    }
    .brigada:before{
      background: url(../img/logob249.png);
    }
    .spua:before{
      background: url(../img/spua32.png);
    }
  }
}