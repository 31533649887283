// ED GRID
// Desde este archivo podrá modificar los components y valores
// por defecto de ed-grid

// flex-container y flex-item
$container: container;
$item: item;

// Ancho máximo de cada container
$max-width: 1200px;

// Separación entre items
$padding: 15px;

// Tamaños. Puede editar los nombres y las
// medidas, añadir más o quitar.
// todo: crear funcion para convertir px a em y usarla aqui
$breakpoints: (
  movil  : 320px,
  tablet : 768px,
  web    : 1024px,
  hd     : 1400px
);

$fractions: 3 6 8;

// Por favor desactive los que no vaya a usar para mejorar el rendimiento

@import "components/function";
@import "components/normalize";
@import "components/base";        // Estilos básicos para RWD
@import "components/core";        // Núcleo de PCS-GRID, grupos y cajas
@import "components/mixins";      // Mixins PCS-GRID
@import "components/desarrollo";  // Modo desarrollo
@import "components/visibilidad"; // Controlar la visibilidad
@import "components/utilidades";  // Utilidades adicionales
@import "components/video";       // Videos responsive
@import "components/flexbox";     // Flexbox
@import "components/offset";      // Offset
