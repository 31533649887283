// Visibilidad

// Visibilidad desde $size
@each $size, $screen-size in $breakpoints{
  @media screen and (max-width: $screen-size - 1){
    .desde-#{$size},
    .from-#{$size}{
      display: none;
    }
  }
}

// Visibilidad hasta $size
@each $size, $screen-size in $breakpoints{
  @media screen and (min-width: $screen-size){
    .hasta-#{$size},
    .to-#{$size}{
      display: none;
    }
  }
}