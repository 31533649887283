// Definir offsets (los han pedido hace mucho)
@each $size, $screen-size in $breakpoints{
  // Loops para tamaños en porcentajes y fracciones
  @media all and (min-width: $screen-size){
    // Tamaños en porcentajes
    @for $i from 1 through 20 {
      .caja.#{$size}-offset-#{$i*5},
      .#{$item}.#{$size}-offset-#{$i*5}{
        margin-left: unquote($i*5 + "%");
      }
    }
    //Tamaño en fracciones
    @each $fraction in $fractions{
      @for $i from 1 through length($fractions){
        .caja.#{$size}-offset-#{$i}-#{$fraction},
        .#{$item}.#{$size}-offset-#{$i}-#{$fraction}{
          margin-left: (100% / $fraction) * $i;
        }
      }
    }
  }
}
