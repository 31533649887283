// ESTILOS DE LAYOUT
.center {
    display: block;
    clear: both;
    margin-right: auto !important;
    margin-left: auto !important;
}

.left {
    float: left;
}

.right{
    float: right;
}

.full {
    width: 100%;
    clear: both;
}

// Las clases de alineaciones de texto siguientes
// serán eliminadas en la v2. Se recomienda usar
// las clases basadas en flexbox

  .center-text,
  .text-center {
    text-align: center;
  }

  .left-text,
  .text-left {
      text-align: left;
  }

  .right-text,
  .text-right {
      text-align: right;
  }


.circle {
    border-radius: 50%;
}

.clearfix {
  &:before, &:after {
    content: "";
    display: table;
    width: 100%;
    clear: both;
  }
}
/*NO PADDING
===========
class="no-padding" al grupo, le da padding cero a todas sus cajas.
class="no-padding" a la caja le da padding cero solo a ella
*/

.no-padding.#{$container} > .#{$item},
.#{$item}.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Controlar el padding*/
.padding {
  padding-left: $padding;
  padding-right: $padding;
}

.padding-2 {
  padding-left: $padding * 2;
  padding-right: $padding * 2;
}

.padding-3 {
  padding-left: $padding * 3;
  padding-right: $padding * 3;
}

// Hacks para alinear icon fonts
[class^="icon-"]:before,
[class*=" icon-"]:before {
  position: relative;
  top: .15em;
  display: inline-block
}

.espacio:before {
  margin-right: .3em;
}
