$pos: .5em;
#slides {
  position: relative;
}
.slidesjs-navigation{
  position: absolute;
  top: 50%;
  z-index: 1000;
  transform: translateY(-50%);
}
.slidesjs-previous, .slidesjs-next{
  font-size: 1.5em;
  color: color(white);
  text-shadow: texto3d(3,rgba(#000,.75));
  opacity: .3;
  transition: $transition;
  @include desde(web){
    font-size: 2em;
  }
  &:hover{
    opacity: 1;
  }
}
.slidesjs-previous{
  left: $pos;
  @include desde(web){
    left: $pos * 2;
  }
}
.slidesjs-next{
  right: $pos;
  @include desde(web){
    right: $pos * 2;
  }
}
.slidesjs-pagination{
  @extend %list-style;
  position: absolute;
  z-index: 1000;
  bottom: $pos*.85;
  display: flex;
  left: 50%;
  opacity: .75;
  transform: translateX(-50%);
  @include from(web){
    bottom: $pos*2;
  }
  li{
    margin: 0 $pos*.85;
    @include from(web){
      margin: $pos*2;
    }
    a{
      color: color(white);
      text-shadow: texto3d(3,rgba(#000,.75));
      font-size: $pos*1.3;
      @include from(web){
        font-size: $pos*2;
      }
    }
  }
}